import {Paragraph}     from "components/Typography";
import {GlobalWrapper} from "shared/Styles/Wrappers";
import styled          from "styled-components";
import ArrowLeft       from "../../../assets/images/arr_left_w.svg";
import QuoteImg        from "../../../assets/images/quotes.svg";

export const Container = styled(GlobalWrapper)`
    overflow: hidden;
    padding: 124px 0;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding: 72px 0;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        padding: 48px 0;
    }
`;

export const SlideWrapper = styled.div`
    position: relative;
    padding-left: 56px;
    padding-right: 96px;
    background-image: url(${QuoteImg});
    background-size: 36px;
    background-position: left 8px;
    background-repeat: no-repeat;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        padding-left: 56px;
        padding-right: 48px;
        background-size: 30px;
        background-position: left 4px;
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        padding-right: 0;
        background-size: 24px 24px;
        padding-left: 42px;
        background-position: left 0px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        padding-left: 0px;
        background-image: none;
    }
`;

export const Wrapper = styled.div`
    max-width: 900px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .swiper-container {
        overflow: visible;

        .swiper-slide {
            ${SlideWrapper} {
                opacity: 0.2;
                transition: 0.4s all ease;
            }

            &.swiper-slide-active {
                ${SlideWrapper} {
                    opacity: 1;
                }
            }
        }
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        max-width: 730px;
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        max-width: 620px;
        padding-left: 24px;
        padding-right: 24px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        max-width: 100%;
        padding-left: 24px;
        padding-right: 24px;
        .swiper-container {
            overflow: hidden;
        }
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
    }
`;

export const TitleWrapper = styled.div`
    margin-bottom: 48px;
    position: relative;
    padding-left: 56px;
    padding-right: 12px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        display: flex;
        align-items: center;
        padding-left: 0;
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        margin-bottom: 36px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Text = styled(Paragraph)`
    font-size: 1.6rem;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 1.4rem;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        font-size: 1.2rem;
    }
`;

export const Author = styled.span`
    font-weight: 700;
    color: ${({color, theme}) => (color ? color : theme.colors.black)};
    font-size: ${({theme}) => theme.fonts.xs};
    text-transform: uppercase;
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        font-size: 1.2rem;
    }
`;

export const AuthorLogo = styled.div`
    width: 40px;
    height: 40px;
    background-image: url(${(props) => (props.logo ? props.logo : null)});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.laptop_big}) {
        width: 30px;
        height: 30px;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        width: 20px;
        height: 20px;
    }
`;

export const SlideFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        margin-top: 24px;
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        margin-top: 12px;
    }
`;

export const NavigationBox = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        position: relative;
        right: auto;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        margin-top: 12px;
    }
`;

export const SlideButton = styled.div`
    width: 42px;
    height: 42px;
    background-image: url(${ArrowLeft});
    background-position: 12px center;
    background-repeat: no-repeat;
    background-size: 20px 18px;
    border-radius: 100%;
    background-color: ${({theme}) => theme.colors.purple};
    cursor: pointer;
    transition: border 0.3s ease;

    &.t_prev_slide {
        transform: rotate(180deg);
    }

    &.t_next_slide {
        margin-left: 8px;
    }

    &:hover {
        background-color: ${({theme}) => theme.colors.button_hover};
        transition: border 0.3s ease;
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: 36px;
        height: 36px;
        background-size: 17px 14px;
        background-position: 10px center;
        &.t_next_slide {
            margin-left: 6px;
        }
    }
    @media screen and (max-width: ${({theme}) =>
            theme.breakpoints.tablet_big}) {
        width: 30px;
        height: 30px;
        background-size: 16px 12px;
        background-position: 8px center;
    }
    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone_big}) {
        &.t_next_slide {
            margin-left: 0px;
        }

        &.t_prev_slide,
        &.t_next_slide {
            margin: 0 4px;
        }
    }
`;
