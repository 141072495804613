import { graphql, useStaticQuery } from "gatsby";

export const useContactPersonPhoto = () =>
  useStaticQuery(
    graphql`
      query {
        furman: file(relativePath: { eq: "images/managers/kubaf.png" }) {
          childImageSharp {
            fluid(maxWidth: 380, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );
