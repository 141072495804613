import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Container,
  SlideWrapper,
  ImageBox,
  TextContainer,
  TextBox,
  TitleWrapper,
  ButtonWrap,
  Image,
  NavigationWrapper,
  SlideButton,
  StyledTitle,
  SlideBackground,
  StyledButton,
} from "./styles";

export const NewSlider = ({ data }) => {
  const [move, setMove] = useState(false);

  const options = {
    loop: true,
    speed: 400,
    touchRatio: 0.4,
    navigation: {
      prevEl: ".prev_slide",
      nextEl: ".next_slide",
    },
    onSlideChangeTransitionStart: () => {
      setMove(true);
    },
    onTransitionEnd: () => {
      setMove(false);
    },
  };

  return (
    <Container>
      <Swiper {...options}>
        {data.map((slide, i) => (
          <SwiperSlide key={i}>
            <SlideWrapper>
              <SlideBackground color={slide.color}>
                {slide.hero.background}
              </SlideBackground>
              <TextContainer>
                <TextBox className={move ? "" : "active"}>
                  <TitleWrapper>
                    <StyledTitle color={slide.hero.color}>
                      {slide.hero.title}
                    </StyledTitle>
                  </TitleWrapper>
                  <ButtonWrap>
                    <StyledButton
                      color={slide.hero.color}
                      to={`realizacje/${slide.slug}`}
                    >
                      Zobacz
                    </StyledButton>
                  </ButtonWrap>
                </TextBox>
              </TextContainer>
              <ImageBox>
                <Image className={move ? "" : "active"}>
                  {slide.hero.detail}
                </Image>
              </ImageBox>
            </SlideWrapper>
          </SwiperSlide>
        ))}
      </Swiper>

      <NavigationWrapper>
        <SlideButton className="prev_slide" />
        <SlideButton className="next_slide" />
      </NavigationWrapper>
    </Container>
  );
};
