export const data = [
  {
    author: "Volkswagen",
    text:
      "Współpracę z agencją InteractiveVision wysoko cenimy za zaangażowanie, elastyczność oraz kompleksowość usług. Zawsze mogliśmy liczyć na profesjonalne doradztwo w zakresie planowanych działań oraz kreatywność. Dotychczasowa współpraca z tą firmą pozwala nam na rekomendowanie jej usług innym.",
    logo: false,
  },
  {
    author: "Seat",
    text:
      "Na podstawie naszej dotychczasowej współpracy z agencją InteractiveVision skłonni jesteśmy stwierdzić, że doświadczenie i wysokie kwalifikacje zespołu, gwarantują wysokiej jakości usługi, dostosowane do naszych indywidualnych potrzeb.",
    logo: false,
  },
  {
    author: "AS Dietetyk mgr Angelika Sowa",
    text:
      "Współpracuje z InteractiveVision od samego początku swojej działalności czyli od 2 lat. InteractiveVision powierzyłam stworzenie mojego logotypu oraz całej identyfikacji wizualnej firmy, następnie przygotowali dla mnie stronę internetową oraz kreacje graficzna wszystkich materiałów reklamowych w tym kampanie reklamowa w google ads. Serdecznie polecam usługi InteractiveVision, dzięki ich pracy moja firma ciągle się rozwija.",
    logo: false,
  },
  {
    author: "Matthias",
    text:
      "Współpracę z agencją InteractiveVision wysoko cenimy za zaangażowanie, elastyczność oraz kompleksowość usług. Zawsze mogliśmy liczyć na profesjonalne doradztwo w zakresie planowanych działań oraz kreatywność. Dotychczasowa współpraca z tą firmą pozwala nam na rekomendowanie jej usług innym.",
    logo: false,
  },
  {
    author: "Toyota Dakar",
    text:
      "Z agencją InteractiveVision współpracujemy od kilku lat. Prace, jakie zlecaliśmy, były zawsze wykonane na bardzo wysokim poziomie jakościowym i z pełną starannością. Kompetencja, rzetelność i przyjemna atmosfera kontaktów biznesowych sprawia, że z przyjemnością polecamy zespół InteractiveVision jako profesjonalny i godny zaufania.",
    logo: false,
  },
  {
    author: "AUDI",
    text:
      "Z agencją InteractiveVision współpracujemy od 7 lat, jesteśmy zadowoleni z przebiegu naszej współpracy. W zakresie naszych działań znajduje się między innymi wsparcie działań reklamowych i marketingowych, pomoc przy organizacji eventów oraz prowadzenie kanałów w social mediach.",
    logo: false,
  },
  {
    author: "FARMA BIAŁKA",
    text:   "Dziękujemy za pomoc w przygotowaniu dokumentacji. Dzięki pomocy InteractiveVision nasza firma otrzymała refundację, a przy okazji profesjonalne działania marketingowe. Jesteśmy bardzo zadowoleni z przebiegu całej współpracy, która zakończyła się sukcesem.",
    logo:   false,
  },
  {
    author: "EXFIT",
    text:   "Z pomocą InteractiveVision otrzymaliśmy refundację PPWB. Agencja towarzyszyła nam na każdym etapie - od rejestracji na platformie po przygotowanie dokumentacji. Platforma internetowa wykonana w ramach usługi pomogła w rozwoju naszej firmy.",
    logo:   false,
  },
  {
    author: "ALDER FINANCE",
    text:   "InteractiveVision wspierało nas na każdym etapie projektu oraz pomogło w wypełnieniu wymaganych dokumentów. To zaangażowany zespół specjalistów, który dba o potrzeby swoich klientów. Usługa, którą dla nas zrealizowała agencja, była na bardzo wysokim poziomie.",
    logo:   false,
  },
];
