import { graphql, useStaticQuery } from "gatsby";

export const useLogosPhoto = () => {
  const data = useStaticQuery(graphql`
    query {
      audi: file(relativePath: { eq: "images/clients/Audi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      beksinski: file(relativePath: { eq: "images/clients/Beksinski.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      delikatesy: file(relativePath: { eq: "images/clients/Delikatesy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      ford: file(relativePath: { eq: "images/clients/Ford.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      frac: file(relativePath: { eq: "images/clients/FRAC.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      harley: file(relativePath: { eq: "images/clients/Harley.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      heating: file(relativePath: { eq: "images/clients/Heating.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      hyundai: file(relativePath: { eq: "images/clients/Hyundai.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      janex: file(relativePath: { eq: "images/clients/Janex.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      ksw: file(relativePath: { eq: "images/clients/KSW.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      lotto: file(relativePath: { eq: "images/clients/Lotto.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      mercedes: file(relativePath: { eq: "images/clients/Mercedes.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      poltra: file(relativePath: { eq: "images/clients/Poltra.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      remet: file(relativePath: { eq: "images/clients/Remet.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      rzeszow: file(relativePath: { eq: "images/clients/Rzeszow.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return data;
};
