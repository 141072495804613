import styled from "styled-components";
import { TitleS, Paragraph } from "components/Typography";
import { GlobalWrapper, TextWrapper } from "shared/Styles/Wrappers";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const Container = styled(GlobalWrapper)`
  padding-top: 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-top: 48px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 24px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 48px 0;
  text-align: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 36px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const Wrapper = styled(TextWrapper)`
  display: flex;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Title = styled(TitleS)``;

export const ContactPersonWrapper = styled.div`
  padding: 72px 0;
  background-color: ${({ theme }) => theme.colors.contact_person};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 48px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
  }
`;

export const ImageBox = styled.div`
  width: 50%;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  padding-right: 48px;
  .gatsby-image-wrapper {
    max-width: 320px;
    width: 100%;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding-right: 36px;
    .gatsby-image-wrapper {
      max-width: 280px;
    }
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 24px;
    justify-content: flex-start;
    .gatsby-image-wrapper {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const TextBox = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 500px) {
    width: 100%;
    text-align: center;
  }
`;

export const TextFlex = styled.div``;

export const Name = styled(TitleS)`
  font-size: 2.2rem;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 2rem;
  }
`;

export const Text = styled(Paragraph)`
  margin: 24px 0;
  display: block;
  max-width: 480px;
  font-size: 1.4rem;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 420px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 24px 0;
  }
  @media screen and (max-width: 500px) {
    max-width: 100%;
  }
`;

export const Profession = styled.span`
  color: ${({ theme }) => theme.colors.gray_black};
  margin-bottom: 4px;
  display: block;
  font-size: 1.2rem;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.2rem;
  }
`;

export const ContactLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const ContactItemSpan = styled.div`
  color: ${({ theme }) => theme.colors.purple};
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 4px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.2rem;
  }
`;

export const ContactLink = styled.a`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
  transition: 0.2s color ease;
  &:hover {
    color: ${({ theme }) => theme.colors.purple};
  }
  &:nth-child(1) {
    font-size: 1.8rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.4rem;
    &:nth-child(1) {
      font-size: 1.4rem;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1.4rem;
    &:nth-child(1) {
      font-size: 1.8rem;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 36px;
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-top: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 36px;
  }
`;

export const StyledButton = styled(AnchorLink)`
  background-color: #000;
  transition: 0.2s color ease;
  padding: 12px 24px;
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.purple};
  color: #fff;
  border: none;
  line-height: 1;
  font-size: 1.4rem;
  font-weight: 700;
  text-align: center;
  transition: 0.2s all ease;
  border-radius: 5px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.button_hover};
    color: #fff;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 10px 18px;
    font-size: 1.2rem;
  }
`;
