import styled from "styled-components";
import { SectionWrapper } from "shared/Styles/Wrappers";
import { Button } from "components/Buttons";
import ArrowLeft from "assets/images/arr_left_w.svg";

export const Container = styled.div`
  max-width: 100vw;
  width: 100%;
  height: calc(100vh - 74px);
  position: relative;
  .swiper-container {
    height: 100%;
    .swiper-wrapper {
      .swiper-slide {
        overflow: hidden;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_big}) {
    height: calc(100vh - 68px);
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_small}) {
    height: 600px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    min-height: 400px;
    height: 400px;
  }
  @media screen and (max-width: 813px) {
    min-height: 315px;
    height: 315px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_big}) and (orientation: portrait) {
    height: 540px;
  }
`;

export const SlideBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => (color ? color : "transparent")};
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`;

export const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  position: relative;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_big}) and (orientation: portrait) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const ImageBox = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50%;
  width: 50%;
  height: 100%;
  max-width: 800px;
  max-height: 800px;
  z-index: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 700px;
    max-height: 700px;
    left: 50%;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    max-width: 400px;
    max-height: 400px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_big}) and (orientation: portrait) {
    height: 52%;
    width: 100%;
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    padding: 24px;
    .gatsby-image-wrapper {
      height: 100%;
      width: 100%;
    }
  }
`;

export const Image = styled.div`
  height: 100%;
  width: 100%;
  opacity: 0;
  transform: translateX(120px);
  display: flex;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  &.active {
    transform: translateX(0px);
    opacity: 1;
    transition-delay: 0.1s;
    transition: 0.4s all ease;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_big}) and (orientation: portrait) {
    transform: translateX(0px) !important;
    opacity: 1 !important;
  }
`;

export const TextContainer = styled(SectionWrapper)`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_big}) and (orientation: portrait) {
    position: relative;
    height: 50%;
    width: 100%;
    padding-top: 24px;
  }
`;

export const TitleWrapper = styled.div`
  margin: 0px 0 48px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin: 0px 0 24px 0;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    margin: 0px 0 12px 0;
  }
`;

export const StyledTitle = styled.div`
  font-size: 6rem;
  line-height: 1;
  font-weight: 800;
  color: ${({ color }) => (color ? color : "#fff")};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 5rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_small}) {
    font-size: 4rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 3.6rem;
  }
  @media screen and (max-width: 813px) {
    font-size: 2.4rem;
  }
`;

export const ButtonWrap = styled.div`
  margin-top: 48px;
  @media screen and (max-width: 813px) {
    margin-top: 24px;
  }
`;

export const TextBox = styled.div`
  width: 100%;
  max-width: 640px;
  padding-bottom: 96px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 540px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    max-width: 400px;
  }
  @media screen and (max-width: 813px) {
    max-width: 260px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_big}) and (orientation: portrait) {
    max-width: 100%;
    height: 100%;
    text-align: center;
  }
`;

export const NavigationWrapper = styled(SectionWrapper)`
  position: absolute;
  bottom: 96px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    bottom: 60px;
  }
  @media screen and (max-width: 1153px) {
    align-items: center;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_big}) and (orientation: portrait) {
    justify-content: center;
    bottom: 70px;
  }
`;

export const SlideButton = styled.div`
  width: 48px;
  height: 48px;
  background-image: url(${ArrowLeft});
  background-position: 14px center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  border-radius: 100%;
  border: 2px solid ${({ theme }) => theme.customColors.justWhite};
  cursor: pointer;
  z-index: 3;
  transition: all 0.2s ease;
  &.prev_slide {
    transform: rotate(180deg);
  }
  &.next_slide {
    margin-left: 12px;
  }
  &:hover {
    background-color: ${({ theme }) => theme.colors.purple};
    border-color: ${({ theme }) => theme.colors.purple};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 36px;
    height: 36px;
    background-size: 14px 14px;
    background-position: 10px center;
    &.next_slide {
      margin-left: 8px;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    background-color: transparent !important;
    border-color: #fff !important;
  }
`;

export const StyledButton = styled(Button)`
  color: ${({ color }) => (color ? color : "#fff")};
  background-color: transparent;
  border: 1px solid ${({ color }) => (color ? color : "#fff")};
  &:hover {
    border-color: ${({ theme }) => theme.colors.purple};
  }
`;
