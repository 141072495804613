import React from "react";
import { TitleL } from "components/Typography";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Container,
  ImageContainer,
  LogosWrapper,
  SliderContainer,
  TitleWrapper,
} from "./styles";
import Image from "gatsby-image";
import { useLogosData } from "shared/Hooks/Logos/useLogosData";

export const Clients = () => {
  const data = useLogosData();

  const options = {
    loop: true,
    speed: 5000,
    slidesPerView: 6,
    spaceBetween: 24,
    breakpoints: {
      320: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
      601: {
        slidesPerView: 5,
      },
    },
    autoplay: {
      delay: 0,
    },
  };

  return (
    <Container>
      <TitleWrapper>
        <TitleL underline>Nasi klienci</TitleL>
      </TitleWrapper>
      <LogosWrapper>
        <Swiper {...options}>
          {data.map((itemArray, key) => (
            <SwiperSlide key={key}>
              <SliderContainer>
                {itemArray.map((item, key) => (
                  <ImageContainer key={key}>
                    <Image fluid={item[1].childImageSharp.fluid} alt="" />
                  </ImageContainer>
                ))}
              </SliderContainer>
            </SwiperSlide>
          ))}
        </Swiper>
      </LogosWrapper>
    </Container>
  );
};
