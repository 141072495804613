import styled from "styled-components";
import { TextWrapper } from "shared/Styles/Wrappers";
import ArrowLeft from "../../../assets/images/arr_left_w.svg";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 75px);
  background-color: #ccc;
  position: relative;
  .swiper-container {
    width: 100%;
    height: 100%;
    .swiper-wrapper {
    }
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    min-height: 560px;
  }
`;

export const Image = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 50%;
  opacity: 0;
  transform: translateX(120px);
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  &.active {
    transform: translateX(0px);
    opacity: 1;
    transition-delay: 0.1s;
    transition: 0.4s all ease;
  }
`;

export const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 20vh;
  position: relative;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.purple};
  @media screen and (max-width: 1153px) {
    padding-left: 100px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    padding-left: 60px;
  }
`;

export const TextBox = styled.div`
  max-width: 440px;
  width: 100%;
  transform: translateX(180px);
  opacity: 0;
  &.active {
    transform: translateX(0px);
    opacity: 1;
    transition: 0.5s all ease;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 400px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    max-width: 320px;
  }
`;

export const Category = styled.div`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.gray_text};
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1.4rem;
  }
`;

export const TitleWrapper = styled.div`
  margin: 0px 0 64px 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin: 24px 0 48px 0;
  }
`;

export const Details = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DetailItem = styled.div`
  margin-right: 48px;
`;

export const DetailTitle = styled.div`
  font-size: ${({ theme }) => theme.fonts.xxs};
  color: ${({ theme }) => theme.colors.gray_text};
  margin-bottom: 4px;
  display: block;
`;

export const DetailContent = styled.p`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.customColors.justWhite};
  font-weight: 800;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 2rem;
  }
`;

export const NavigationWrapper = styled(TextWrapper)`
  position: absolute;
  bottom: 96px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    bottom: 60px;
  }
  @media screen and (max-width: 1153px) {
    align-items: center;
  }
`;

export const SlideButton = styled.div`
  width: 48px;
  height: 48px;
  background-image: url(${ArrowLeft});
  background-position: 14px center;
  background-repeat: no-repeat;
  background-size: 18px 18px;
  border-radius: 100%;
  border: 2px solid ${({ theme }) => theme.customColors.justWhite};
  cursor: pointer;
  /* filter: brightness(500%); */
  transition: border 0.3s ease;
  &.prev_slide {
    transform: rotate(180deg);
  }
  &.next_slide {
    margin-left: 12px;
  }
  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.purple};
    transition: border 0.3s ease;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 36px;
    height: 36px;
    background-size: 14px 14px;
    background-position: 10px center;
    &.next_slide {
      margin-left: 8px;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    width: 36px;
    height: 36px;
    background-size: 12px 12px;
    background-position: 8px center;
  }
`;

export const Pagination = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + 30px);
  left: auto;
  bottom: auto;
  display: flex;
  color: #fff;
  font-size: 2.8rem;
  font-weight: 800;
  letter-spacing: 0.4rem;
  vertical-align: bottom;
  width: 44px;
  .swiper-pagination-total {
    font-size: 2rem;
    position: absolute;
    bottom: 0px;
    right: -4px;
  }
  @media screen and (max-width: 1153px) {
    left: 0;
    position: relative;
    transform: none;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 2.6rem;
    margin-right: 20px;
    .swiper-pagination-total {
      font-size: 2rem;
      bottom: -3px;
      right: 4px;
    }
  }
`;

export const ButtonWrap = styled.div`
  margin-top: 64px;
`;

export const SlideLogo = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  width: 150px;
  z-index: 1;
`;
