import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { TitleM } from "components/Typography";
import {data as data_default} from '../../../data/testimonials_data';

import {
  Container,
  Wrapper,
  TitleWrapper,
  NavigationBox,
  SlideWrapper,
  Text,
  Author,
  // AuthorLogo,
  SlideFooter,
  SlideButton,
} from "./styles";

export const Testimonials = ({data}) => {

  const data_testimonials = data ? data : data_default;

  const options = {
    spaceBetween: 64,
    loop: true,
    speed: 1000,
    loopedSlides: 4,
    autoplay: {
      delay: 6000,
    },
    navigation: {
      nextEl: ".t_next_slide",
      prevEl: ".t_prev_slide",
    },
  };

  return (
    <Container>
      <Wrapper>
        <TitleWrapper>
          <TitleM as="h2">Opinie naszych klientów</TitleM>
          <NavigationBox>
            <SlideButton className="t_prev_slide" />
            <SlideButton className="t_next_slide" />
          </NavigationBox>
        </TitleWrapper>
        <Swiper {...options}>
          {data_testimonials.map((slide, i) => (
            <SwiperSlide key={i}>
              <SlideWrapper>
                <Text>{slide.text}</Text>
                <SlideFooter>
                  <Author>{slide.author}</Author>
                  {/* <AuthorLogo logo={slide.logo}></AuthorLogo> */}
                </SlideFooter>
              </SlideWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
      </Wrapper>
    </Container>
  );
};
