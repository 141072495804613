import React from "react";
import VideoUrl from "assets/videos/video.mp4";
import {
  Container,
  VideoContainer,
  Video,
  TextWrapper,
  Title,
  Header,
  StyledButton,
} from "./styles";
import { ViewMore } from "components/ViewMore";
import { useDispatch } from "react-redux";
import { videoModalOpen } from "store/video/actions";

export const NewHero = () => {
  const dispatch = useDispatch();
  const handleOpenVideo = () => dispatch(videoModalOpen());

  return (
    <Container>
      <VideoContainer>
        <Video src={VideoUrl} autoPlay muted loop playsInline />
        <TextWrapper>
          <Title>Agencja Interaktywna InteractiveVision</Title>
          <Header>
            Kreujemy dla Ciebie
            <br />
            Zobacz, co robimy
          </Header>
          <StyledButton onClick={handleOpenVideo}>Zobacz showreel</StyledButton>
        </TextWrapper>
        <ViewMore />
      </VideoContainer>
    </Container>
  );
};
