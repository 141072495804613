import styled from "styled-components";
import { SectionWrapper } from "shared/Styles/Wrappers";

export const Container = styled(SectionWrapper)`
  padding: 64px 0 40px 0;
  max-width: 1200px;
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 36px 0 12px 0;
    max-width: 800px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_small}) {
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 24px 0 0px 0;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 64px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-bottom: 48px;
  }
`;

export const LogosWrapper = styled.div`
  z-index: 5;
  .swiper-container {
    .swiper-wrapper {
      transition-timing-function: linear !important;
      .swiper-slide {
      }
    }
  }
`;

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.phone_big}) {
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  padding-bottom: 24px;

  .gatsby-image-wrapper {
    transition: transform 0.2s transform ease-in;
    transform: scale(1.02);
  }
`;
