import { useLogosPhoto } from "shared/Hooks/Logos/useLogosPhoto";

export const useLogosData = () => {
  const logos = Object.entries(useLogosPhoto());
  const logosGroup = [];
  for (let i = 0; i < logos.length / 3; i++) {
    logosGroup.push(logos.slice(i * 3, i * 3 + 3));
  }
  return logosGroup;
};
