import styled from "styled-components";
import { SectionWrapper } from "shared/Styles/Wrappers";
import { Button } from "components/Buttons";

export const Container = styled.div`
  max-width: 100vw;
  width: 100%;
  height: 100vh;
  position: relative;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    height: 100vh;
    max-height: -webkit-fill-available;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const TextWrapper = styled(SectionWrapper)`
  color: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Title = styled.h1`
  font-size: 1.6rem;
  font-weight: 800;
  margin-bottom: 12px;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_big}) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    font-size: 1rem;
  }
`;

export const Header = styled.div`
  font-weight: 800;
  font-size: 10rem;
  line-height: 1;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_big}) {
    font-size: 8rem;
  }
  line-height: 1;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 7rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 5rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone_big}) {
    font-size: 3rem;
  }
  @media screen and (max-width: 330px) {
    font-size: 2.4rem;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 48px;
  background-color: #000;
  &:hover {
    background-color: ${({ theme }) => theme.colors.purple};
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-top: 24px;
  }
`;
